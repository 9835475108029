import { HttpClient } from '@angular/common/http';
import { Client, ClientOptions } from '@microsoft/microsoft-graph-client';
import { UserCredential } from 'firebase/auth';

import { ClientCredentialProvider } from './client.credential.provider';

export class GraphClientService {
	constructor(
		private httpClient: HttpClient,
		private userCredential: UserCredential
	) {}

	public async getUserProfile(): Promise<any> {
		const client: Client = this.createGraphClient();

		return client.api(`/me`).get();
	}

	private createGraphClient(): Client {
		const clientOptions: ClientOptions = {
			authProvider: new ClientCredentialProvider(this.httpClient, this.userCredential)
		};
		return Client.initWithMiddleware(clientOptions);
	}
}
