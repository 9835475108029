import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
	Auth,
	getRedirectResult,
	OAuthProvider,
	onAuthStateChanged,
	reauthenticateWithRedirect,
	signInWithRedirect,
	signOut,
	useDeviceLanguage,
	User as FirebaseUser
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { GraphClientService } from '@rle-core/msgraph/graph-client.service';
import { environment } from '@rle-environments/environment';
import { NotificationService } from '@rle-shared/notification.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of, ReplaySubject, Subject, Subscription, TimeoutError } from 'rxjs';
import { take, takeUntil, timeout } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public static PASSWORD_STRENGTH_REGEX = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"§$%&/()=?*+#<>@]).{8,}';
	public static PASSWORD_STRENGTH_SPECIAL_CHARS = '!"§$%&/()=?*+#<>@';

	private static REDIRECT_URL_STORAGE_KEY = 'login_redirect_url';

	public loggedInUser: Observable<FirebaseUser | null>;
	public loggedInUserId: Observable<string | null>;
	public tokenRefreshed: Observable<boolean>;

	private loggedInUserSubscription?: Subscription;
	private loggedInUserSubject = new ReplaySubject<FirebaseUser | null>(1);
	private loggedInUserIdSubject = new ReplaySubject<string | null>(1);
	private currentLoggedInUser: FirebaseUser | null = null;
	private authStateChangeInProgress = false;
	private isLoginInProgress = false;
	private tokenRefreshedSubject = new Subject<boolean>();

	constructor(
		private auth: Auth,
		private permissionsService: NgxPermissionsService,
		private router: Router,
		private platform: Platform,
		private ngZone: NgZone,
		private notificationService: NotificationService,
		private httpClient: HttpClient
	) {
		this.loggedInUserIdSubject.next(null);
		this.loggedInUser = this.loggedInUserSubject.asObservable();
		this.loggedInUserId = this.loggedInUserIdSubject.asObservable();
		this.tokenRefreshed = this.tokenRefreshedSubject.asObservable();
	}

	public init(): Promise<boolean> {
		return (
			this.platform
				.ready()
				.then(() => useDeviceLanguage(this.auth))
				.then(() => this.initAuthStateHandler())
				// Load current Firebase user to ensure that user is loaded before first login check
				.then(() => this.processLoginReturnResult())
				.catch(error => {
					if (error?.code === 'auth/user-cancelled') {
						return Promise.resolve(false);
					}

					this.processError(error, true);
					return signOut(this.auth).then(() => Promise.resolve(false));
				})
		);
	}

	public isLoggedIn(): boolean {
		return !!this.currentLoggedInUser;
	}

	public getCurrentLoggedInUser(): FirebaseUser | null {
		return this.currentLoggedInUser ?? null;
	}

	public doLogin(origin?: string): Promise<void> {
		if (environment.clientLogEnabled) {
			console.log(`Show login with origin: ${origin}`);
		}
		this.setRedirectUrl(origin ?? null);

		const provider = new OAuthProvider('oidc.keycloak');
		// provider.setCustomParameters({
		// 	// Target specific email with login hint.
		// 	login_hint: 'user@example.com'
		// });
		provider.addScope('organisation');
		return signInWithRedirect(this.auth, provider).catch(error => this.processError(error));
	}

	public buildWaitUntilTokenRefreshPromise(destruction: Subject<void>): Promise<void> {
		return new Promise((resolve, reject) =>
			this.tokenRefreshed.pipe(take(1), timeout(20000), takeUntil(destruction)).subscribe({
				next: () => resolve(),
				error: e => (e instanceof TimeoutError ? resolve() : reject())
			})
		);
	}

	public relogin(): Promise<FirebaseUser | null> {
		return this.auth.currentUser ? this.reloginFor(this.auth.currentUser) : Promise.resolve(null);
	}

	public async logout(): Promise<boolean> {
		try {
			await signOut(this.auth);
			window.location.reload(); //.href = '/login/logout';
			return true;
		} catch (err) {
			await this.processError(err);
			return false;
		}
	}

	private initAuthStateHandler(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			onAuthStateChanged(
				this.auth,
				firebaseUser => {
					this.ngZone.run(async () => {
						await this.notificationService.showSpinner();
						this.authStateChangeInProgress = true;
						if (environment.clientLogEnabled) {
							console.log('authState changed: ', firebaseUser?.uid);
						}
						if (this.loggedInUserSubscription) {
							this.loggedInUserSubscription.unsubscribe();
						}
						this.loggedInUserSubscription = this.processUser(firebaseUser).subscribe({
							next: firebaseUser => {
								this.ngZone.run(async () => {
									await this.notificationService.hideSpinner();
									this.setCurrentUser(firebaseUser);

									if (this.authStateChangeInProgress) {
										this.redirectAfterLogin();
									}
									this.authStateChangeInProgress = false;
									this.isLoginInProgress = false;
									resolve(true);
								});
							},
							error: error => {
								this.ngZone.run(async () => {
									// Ignore error if processing was canceled
									if (error) {
										this.processError(error, true);
									} else {
										await this.notificationService.hideSpinner();
									}
									this.authStateChangeInProgress = false;
									this.isLoginInProgress = false;
									resolve(false);
								});
							}
						});
					});
				},
				async error => {
					this.authStateChangeInProgress = false;
					this.isLoginInProgress = false;
					await this.notificationService.hideSpinner();
					reject(error);
				}
			);
		});
	}

	private processLoginReturnResult(): Promise<boolean> {
		if (Capacitor.isNativePlatform()) {
			return Promise.resolve(true);
		}
		// Check result after redirect back from Identity Provider on web
		return getRedirectResult(this.auth).then(userCredential => {
			if (userCredential) {
				const credentials = OAuthProvider.credentialFromResult(userCredential);
				console.log(credentials); // Access Token + ID Token from Microsoft
				console.log(userCredential); // Decoded Firebase ID Token

				const graphClient = new GraphClientService(this.httpClient, userCredential);
				graphClient.getUserProfile().then(profile => console.log('User profile:', profile));
			}
			return Promise.resolve(!!userCredential);
		});
	}

	private processUser(firebaseUser: FirebaseUser | null): Observable<FirebaseUser | null> {
		if (firebaseUser) {
			return this.auth.currentUser ? from(this.processAuthToken(firebaseUser)) : of(null);
		} else {
			this.permissionsService.flushPermissions();
			this.setRedirectUrl(null);
			return of(null);
		}
	}

	private processAuthToken(firebaseUser: FirebaseUser): Promise<FirebaseUser> {
		if (!firebaseUser) {
			return Promise.resolve(firebaseUser);
		}

		// Force token refresh if the update timestamp of the old and the new loggedin user differs
		const forceRefresh = true;
		// !!this.currentLoggedInUser?.uid &&
		// this.currentLoggedInUser.uid === firebaseUser?.uid &&
		// this.currentLoggedInUser.tokenClaimsUpdatedAt?.toMillis() !==
		// 	userTuple.user?.tokenClaimsUpdatedAt?.toMillis();

		return firebaseUser.getIdTokenResult(forceRefresh).then(tokenResult => {
			console.log('Claims:', tokenResult?.claims);
			let permissionsList: string[] = (tokenResult?.claims?.['permissions'] as string[]) ?? [];

			if (environment.clientLogEnabled) {
				console.log('Force token refresh: ', forceRefresh);
				console.log('Token claims: ', tokenResult.claims);
				console.log('User permissions: ', permissionsList);
			}

			this.tokenRefreshedSubject.next(true);
			return Promise.resolve(firebaseUser);
		});
	}

	private setCurrentUser(firebaseUser: FirebaseUser | null): void {
		const oldUserId = this.currentLoggedInUser?.uid ?? null;

		this.currentLoggedInUser = firebaseUser;
		this.loggedInUserSubject.next(firebaseUser ?? null);
		if (oldUserId !== (firebaseUser?.uid ?? null)) {
			this.loggedInUserIdSubject.next(firebaseUser?.uid ?? null);
		}
	}

	private setRedirectUrl(url: string | null): void {
		if (url && /^\/?login(\/.*)?$/i.test(url)) {
			return;
		}
		if (url) {
			localStorage.setItem(AuthService.REDIRECT_URL_STORAGE_KEY, url);
		} else {
			localStorage.removeItem(AuthService.REDIRECT_URL_STORAGE_KEY);
		}
	}

	private getRedirectUrl(): string | null {
		return localStorage.getItem(AuthService.REDIRECT_URL_STORAGE_KEY);
	}

	private redirectAfterLogin(): void {
		const redirectUrl = this.getRedirectUrl();
		if (redirectUrl) {
			this.router.navigateByUrl((redirectUrl.startsWith('/') ? '' : '/') + redirectUrl, { replaceUrl: true });
			this.setRedirectUrl(null);
		} else if (this.authStateChangeInProgress && this.isLoginInProgress && Capacitor.isNativePlatform()) {
			// Ensure a page reload after password based registration / login, so that the AuthGuard comes in place
			// for correct redirection.
			// Also on native devices after logout and relogin, no redirect is set, so leave login page and goto home.
			this.router.navigateByUrl('/', { replaceUrl: true });
		}
	}

	private reloginFor(firebaseUser: FirebaseUser): Promise<FirebaseUser | null> {
		return reauthenticateWithRedirect(firebaseUser, new OAuthProvider('oidc.keycloak')).then(() =>
			Promise.resolve(this.auth.currentUser)
		);
	}

	private async processError(error: any, navigateToErrorPage = false): Promise<void> {
		if (environment.clientLogEnabled) {
			console.error(error);
		}
		await this.notificationService.hideSpinner();
		return navigateToErrorPage
			? this.router.navigateByUrl('/error').then(() => Promise.resolve())
			: this.notificationService.showMessage(this.getErrorMessage(error));
	}

	private getErrorMessage(error: any): string {
		const code =
			error && Object.prototype.hasOwnProperty.call(error, 'code')
				? error.code
				: error && Object.prototype.hasOwnProperty.call(error, 'message')
					? error.message
					: error && typeof error === 'string'
						? error
						: '';
		switch (code) {
			case 'auth/user-not-found':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/wrong-password':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/email-already-in-use':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/credential-already-in-use':
				return 'auth.messages.accountAlreadyExistOrLinkedToAnotherAcc';
			case 'auth/account-exists-with-different-credential':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/app-deleted':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/app-not-authorized':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-email':
				return 'auth.messages.enteredEmailIsInvalid';
			case 'auth/argument-error':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/invalid-api-key':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-user-token':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-tenant-id':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/network-request-failed':
				return 'auth.messages.networkError';
			case 'auth/operation-not-allowed':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/operation-not-supported-in-this-environment':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/requires-recent-login':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/too-many-requests':
				return 'auth.messages.tooManyAttempts';
			case 'auth/unauthorized-domain':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/user-disabled':
				return 'auth.messages.accountDisabled';
			case 'auth/user-token-expired':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/web-storage-unsupported':
				return 'auth.messages.errorPleaseTryAgain';
			default:
				return Capacitor.isNativePlatform()
					? 'auth.messages.errorPleaseRestartApp'
					: 'auth.messages.errorPleaseReloadBrowser';
		}
	}
}
