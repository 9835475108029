// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Constants } from './version';

export const environment = {
	version: Constants.version,
	production: false,
	firebase: {
		apiKey: 'AIzaSyAZdeKDGAiEmGztx6Pnn48vumOa5F2P-tA',
		authDomain: 'rle-portal-projects-dev.firebaseapp.com',
		projectId: 'rle-portal-projects-dev',
		storageBucket: 'rle-portal-projects-dev.appspot.com',
		messagingSenderId: '760909253773',
		appId: '1:760909253773:web:bf1d852205fe8d63d7e6c4'
	},
	featureToggles: {},
	// Enable client side logging
	clientLogEnabled: true,
	// Use local emulator for authentication (must be started before)
	useAuthEmulator: false,
	// Use local emulator for database (must be started before)
	useDBEmulator: false,
	// Use local emulator for storage (must be started before)
	useStorageEmulator: false,
	// Use local emulator for functions (must be started before)
	useFunctionsEmulator: false,
	// Use mock data instead of accessing the real database
	useMockData: false,
	// Override system settings to force the theme mode to 'light' or 'dark'
	forceThemeMode: null,
	// Add an extra top padding for the device status bar / notch
	forceStatusbarPadding: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
